<template>
  <div class="container_margin">
    <b-modal size="lg" id="modal-Unsubscribe" centered hide-footer hide-header>
      <PageLoader
        v-if="pageLoading"
        :loading="pageLoading"
        replace_msg="Please wait a moment, we are cancelling your subscription.">
      </PageLoader>

      <div v-else class="card-body p-3">
        <h5 class="font-weight-bold card-title text-center">
          {{ "Are you sure you want to cancel your subscription?" }}
        </h5>

        <div class="card-text mt-3">
          <h6 class="text-center">
            {{
              "If you cancel your current subscription, you will continue to have access to your account until the end of the current subscription period. After that, your account will no longer be accessible unless you renew your subscription."
            }}
          </h6>
        </div>

        <div class="row card-text justify-content-center mt-4">
          <div class="col-6">
            <button
              class="btn btn-success float-right"
              @click="$bvModal.hide('modal-Unsubscribe')"
            >
              {{ "Keep Subscription" }}
            </button>
          </div>

          <div class="col-6">
            <button
              class="btn btn-danger float-left"
              @click.once="unsubscribe()"
            >
              {{ "Cancel Subscription" }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      size="md"
      id="modal-sucess-unsubscribe"
      centered
      hide-footer
      hide-header
    >
      <div class="card-body p-3">
        <h5 class="font-weight-bold card-title text-center">
          {{ "Subscription Cancelled" }}
        </h5>

        <div class="card-text mt-3">
          <h6 class="text-center">
            {{
              "Your account will remain active until the end of the current subscription period."
            }}
          </h6>
        </div>

        <div class="row card-text justify-content-center mt-4">
          <div class="col-2">
            <button
              class="btn btn-primary text-center"
              @click="$bvModal.hide('modal-sucess-unsubscribe')"
            >
              {{ "Close" }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <div class="row h-100 justify-content-center">
      <div class="col">
        <div class="row">
          <div class="col">
            <h4 class="font-weight-bold">{{ "Billing Details" }}</h4>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card border shadow-sm p-4 mt-3 rounded">
              <div class="row">
                <div class="col text-center">
                  <b>{{ "Current Subscription" }} </b>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col text-center">
                  <h3 class="font-weight-bold">
                    {{ dev ? "" : ((period_type + "ly")) | capitalize }}
                    {{ dev ? "&lt;" : plan_name }}
                      Plan
                    {{ dev ? " Name &gt;" : "" }}
                  </h3>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col text-center">
                  <div class="d-flex align-items-center justify-content-center">
                    <h1 class="font-weight-bold text-blue me-1">
                      ${{ dev ? "&lt; Price &gt;" : price }}
                    </h1>
                    <span
                      v-if="period_type === 'month'"
                      class="font-weight-bold text-blue mt-1"
                      >/month</span
                    >
                    <span v-else class="font-weight-bold text-blue mt-1"
                      >/year</span
                    >
                  </div>
                </div>
              </div>

              <div class="row mt-3 justify-content-center">
                <div class="col-6">
                  <table class="table table-bordered">
                    <thead class="table-light">
                      <tr>
                        <th scope="col" class="text-center">Created Date</th>
                        <!-- <th scope="col" class="text-center">Valid Until</th> -->
                        <th scope="col" class="text-center">Next Billing</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">
                          {{ period_start_date | formatDateLoc }}
                        </td>
                        <!-- <td class="text-center">
                          {{ expiry_date | formatDateLoc }}
                        </td> -->
                        <td class="text-center">
                          {{ expiry_date | formatDateLoc }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- 
              <div class="row mt-2 justify-content-center">
                <div class="col-6">
                  <button class="btn btn-primary w-100">Upgrade Plan</button>
                </div>
              </div> -->

              <div
                class="row mt-4 justify-content-center"
                v-if="hasSubscription == true && isExpired == false"
              >
                <div class="col-4">
                  <button
                    @click="handleDisabledClick"
                    class="btn btn-primary w-100"
                  >
                    Cancel Subscription
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row mt-4">
          <div class="col">
            <h4 class="font-weight-bold">{{ "Plan Renewal" }}</h4>
          </div>
        </div> -->

        <!-- <div class="row">
          <div class="col-sm-12 col-md-8 col-lg-10 col-xl-6">
            <div class="card border shadow-sm p-3 mt-2 rounded">
              <form :action="action" method="POST" id="payment-form">
                <div class="row mt-2">
                  <div class="col-md-4 col-sm-12">
                    <b> {{ "Type" }}</b>
                  </div>
                  <div class="col d-flex align-items-center">
                    <select
                      class="form-select w-100 h-100 rounded"
                      id="inputGroupSelect04"
                      aria-label="Example select with button addon"
                      v-model="chosenPeriod"
                      placeholder="Country"
                    >
                      <option
                        :value="period.id"
                        :key="index"
                        v-for="(period, index) in periods"
                      >
                        {{ period.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-md-4 col-sm-12">
                    <b>Period of Renewal</b>
                  </div>

                  <div class="col d-flex align-items-center">
                    <b-form-spinbutton
                      id="demo-sb"
                      v-model="month"
                      min="1"
                      max="100"
                      class="period__spinbutton"
                    ></b-form-spinbutton>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-4 col-sm-12 align-self-center">
                    <b>{{ "Card Number" }}</b>
                  </div>

                  <div class="col align-self-center">
                    <div id="card-element" class="cardno__input"></div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col">
                    <button id="card-button" class="btn btn-primary w-100">
                      {{ "Renew Plan" }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script src="https://js.stripe.com/v3/"></script>

<script>
/* eslint-disable */
import PageLoader from "@/components/PageLoader.vue";
import Auth from "@/store/Auth.js";
import Plan from "@/store/Plan.js";

export default {
  components: {
    PageLoader,
  },

  data() {
    return {
      dev: false,
      period_type: "",
      plan_name: "",
      plan_id: null,
      price_id: null,
      price: null,
      hasSubscription: JSON.parse(localStorage.userInfo).has_subscription,
      isExpired: JSON.parse(localStorage.userInfo).isExpired,
      expiry_date: JSON.parse(localStorage.userInfo).expiry_date,
      period_start_date: JSON.parse(localStorage.userInfo).period_start_date,
      pageLoading: false,
    };
  },

  methods: {
    unsubscribe() {
      this.pageLoading = true;
      Plan.cancel_subscription()
        .then((response) => {
          this.$toast.success(response.data.message);

          Auth.getMe().then((response_data) => {
            localStorage.setItem(
              "userInfo",
              JSON.stringify(response_data.data.data)
            );
            this.has_subscription = response_data.data.data.has_subscription;

            this.$bvModal.hide("modal-Unsubscribe");

            this.$bvModal.show("modal-sucess-unsubscribe");
            this.pageLoading = false;

            if (response.data.logout == true) {
              localStorage.removeItem("accessToken");
              localStorage.removeItem("userInfo");
              localStorage.removeItem("me");
              this.$router.push("/login");
            } else {
            }
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },

    handleDisabledClick() {
      if (this.has_subscription === false) {
        this.$toast.error("Subscription has already been Cancelled!", {
          duration: 3000,
        });
      } else {
        this.$bvModal.show("modal-Unsubscribe");
      }
    },
  },

  mounted() {
    // cardElement.mount("#card-element");
  },

  created() {
    const current_plan_price = JSON.parse(localStorage.userInfo).current_plan_price;
    this.plan_id = current_plan_price?.plan_id ?? null;
    this.price_id = current_plan_price?.id ?? null;
    this.price = current_plan_price?.price ?? null;

    if (
      this.plan_id == null &&
      JSON.parse(localStorage.userInfo).not_have_plan_yet == null
    ) {
      this.dev = true;
    } else if (this.plan_id === 500) {
      this.plan_name = "Essential";
    } else if (this.plan_id === 510) {
      this.plan_name = "Complete";
    } else {
      this.plan_name = "No";
    }

    if (this.price_id === 500 || this.price_id === 510) {
      this.period_type = "month";
    } else if (this.price_id === 501 || this.price_id === 511) {
      this.period_type = "year";
    }
  },
};
</script>

<style scoped>
.container_margin {
  padding: 2rem;
}
.cardno__input {
  margin-top: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: transparent;
  width: 100%;

  height: 35px;
}

.nexcard-img {
  width: 22rem;
}
</style>
